import React, { Fragment, useEffect } from "react";

export default function Modal({ children, title, opened, closeAction, description, headerClasses, headerExtraContent }) {

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.classList.contains('modal-container')) {
        closeAction()
      }
    })
  }, [])

  return (
    <Fragment>
      {opened ? (
        <div>
          <div
            className="modal-container tw-justify-center tw-items-center tw-flex tw-overflow-x-hidden tw-overflow-y-auto tw-fixed tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none"
          >
            <div className="tw-relative tw-w-auto tw-mt-6 tw-mx-auto tw-max-w-3xl" style={{
                maxHeight: 'calc(100Vh - 110px)',
                overflowY: 'auto'
              }}>
              {/*content*/}
              <div className="tw-shadow-lg tw-relative tw-flex tw-flex-col tw-w-full tw-bg-white tw-outline-none focus:tw-outline-none">
                {/*header*/}
                <div className={`tw-flex tw-flex-col tw-justify-between tw-px-14 tw-pt-14 tw-pb-5 tw-rounded-t ${headerClasses}`}>
                  { title && (
                    <h3 className="tw-text-main1 tw-text-2xl md:tw-text-4xl tw-font-semibold">
                      { title }
                    </h3>
                  )}
                  { description && (
                    <p className="tw-text-main1 tw-text-xl">
                      { description }
                    </p>
                  )}
                  <div className="close tw-cursor-pointer tw-absolute tw-top-5 tw-right-5"
                        onClick={ closeAction }>
                    <i className="plus icon tw-rotate-45 tw-transform"></i>
                  </div>
                </div>
                { headerExtraContent }
                {/*body*/}
                <div className="tw-relative tw-px-14 tw-pb-14 tw-flex-auto">
                  { children }
                </div>
              </div>
            </div>
          </div>
          <div className="tw-opacity-25 tw-fixed tw-inset-0 tw-z-40 tw-bg-black"></div>
        </div>
      ) : null}
    </Fragment>
  );
}