import React, { useState, Fragment, useEffect } from "react";
import axios from 'axios'
import Modal from '../modal'
import InputText from '../inputs_components/InputText'
import LoadingSpinner from '../loader_spinner'

export default function LoginModal() {
  const [openModal, setOpenModal] = useState(false)
  const [targetType, setTargetType] = useState('other')
  const [targetUrl, setTargetUrl] = useState('/')
  const [activeTab, setActiveTab] = useState(0)

  const modaMessages = {
    'class': "Just a few quick details, and you'll be ready to create your offer and support the causes you care about!",
    'service': "Just a few quick details, and you'll be ready to create your offer and support the causes you care about!",
    'product': "Just a few quick details, and you'll be ready to create your offer and support the causes you care about!",
    'event': "Just a few quick details, and you'll be ready to create your offer and support the causes you care about!",
    'project': "You'll need an account to create a project.",
    'nonprofit': "You'll need an account to add a nonprofit.",
    'sign_up': 'Create your account to get started!',
    'offer': "Just a few quick details, and you'll be ready to create your offer and support the causes you care about!",
    'chat': "Create your account to ask questions and connect with service, event, and product providers.",
    'buy_ticket': "Create your account to make a purchase and support local nonprofits. Every purchase helps fund important work in your community.",
    'hire': "Create your account to make a hire and support local nonprofits. Every purchase helps fund important work in your community.",
    'other': ''
  }

  useEffect(() => {
    window.addEventListener('OpenLoginModal', (e) => {
      if (!e.detail) return false

      setOpenModal(e.detail.open)
      if (e.detail.targetType) setTargetType(e.detail.targetType)
      if (e.detail.targetUrl) setTargetUrl(e.detail.targetUrl)
      setActiveTab(e.detail.activeTab)
    })
  }, [])

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    zipcode: '',
    city: '',
    password: '',
    state: ''
  })


  const registerUserInfo = () => {
    let errorMessage = []

    if (!userInfo.first_name) {
      errorMessage.push("First name is required")
    }

    if (!userInfo.last_name) {
      errorMessage.push("Last name is required")
    }

    if (!userInfo.email) {
      errorMessage.push("Email is required")
    }

    if (!userInfo.zipcode) {
      errorMessage.push("Zip code is required")
    }

    if (!userInfo.password) {
      errorMessage.push("Password is required")
    }

    if (errorMessage.length > 0) {
      setErrors({full_message: errorMessage.join(", ")})
      return false
    }

    setLoading(true)
    const csrfElement = document.querySelector('meta[name="csrf-token"]')
    const csrfToken = csrfElement && csrfElement.getAttribute("content")

    const formData = new FormData();

    formData.append("authenticity_token", csrfToken);

    userInfo.first_name && formData.append("user[first_name]", userInfo.first_name);
    userInfo.last_name && formData.append("user[last_name]", userInfo.last_name);
    userInfo.email && formData.append("user[email]", userInfo.email);
    userInfo.password && formData.append("user[password]", userInfo.password);

    userInfo.address && formData.append("user[address_attributes][address1]", userInfo.address)
    userInfo.zipcode && formData.append("user[address_attributes][zipcode]", userInfo.zipcode)
    userInfo.city && formData.append("user[address_attributes][city]", userInfo.city)
    userInfo.state && formData.append("user[address_attributes][state_id]", userInfo.state)

    const url = `/v2/users/sign_up`
    const method = 'post'
    const config = {
      method: method,
      url: url,
      data: formData,
      headers: {'X-Requested-With': 'XMLHttpRequest'}
    };
    axios(config).then(function (response) {
      setLoading(false)
      window.location.href = targetUrl;
    }).catch(function (error) {
      setLoading(false)
      const response = error.response
      setErrors({
        ...errors,
        ...response.data
      })
    });
  }

  const loginUserInfo = () => {
    let errorMessage = []

    if (!userInfo.email) {
      errorMessage.push("Email is required")
    }

    if (!userInfo.password) {
      errorMessage.push("Password is required")
    }

    if (errorMessage.length > 0) {
      setErrors({full_message: errorMessage.join(", ")})
      return false
    }

    setLoading(true)
    const csrfElement = document.querySelector('meta[name="csrf-token"]')
    const csrfToken = csrfElement && csrfElement.getAttribute("content")

    const formData = new FormData();

    formData.append("authenticity_token", csrfToken);

    userInfo.email && formData.append("email", userInfo.email);
    userInfo.password && formData.append("password", userInfo.password);

    const url = `/v2/users/login`
    const method = 'post'
    const config = {
      method: method,
      url: url,
      data: formData,
      headers: {'X-Requested-With': 'XMLHttpRequest'}
    };
    axios(config).then(function (response) {
      setLoading(false)
      window.location.href = targetUrl;

    }).catch(function (error) {
      setLoading(false)
      const response = error.response
      setErrors({
        ...errors,
        ...response.data
      })
    });
  }

  const changeZipcode = (zipcodeValue) => {
    $.ajax({
      type: 'POST',
      url: '/users/search_zipcode',
      data: { zipcode: zipcodeValue },
      success: (data) => {
        setUserInfo({
          ...userInfo,
          zipcode: zipcodeValue,
          city: data.city,
          state: data.state_id
        })
      },
      error: (e) => {
        setUserInfo({
          ...userInfo,
          zipcode: zipcodeValue,
          city: "",
          state: ""
        })
      }
    });
  }

  return (
    <Fragment>
      <Modal
        title="Almost there!"
        description={ modaMessages[targetType] }
        headerClasses="tw-text-center"
        closeAction={ () => setOpenModal(false) }
        headerExtraContent={(
          <div>
            <ul className="tw-border-0 tw-border-solid tw-flex tw-w-full tw-py-0 tw-justify-center tw-border-b tw-border-captures tw-m-0 tw-px-0">
              <li
                onClick={ () => setActiveTab(0) }
                className={`tw-text-xl tw-font-semibold tw-list-none tw-cursor-pointer tw-px-8 tw-py-4 ${ activeTab == 0 ? 'tw-border-0 tw-border-solid tw-border-b-4 tw-border-color1 ' : '' }`}>
                Create account
              </li>
              <li
                onClick={ () => setActiveTab(1) }
                className={`tw-text-xl tw-font-semibold tw-list-none tw-cursor-pointer tw-px-8 tw-py-4 ${ activeTab == 1 ? 'tw-border-0 tw-border-solid tw-border-b-4 tw-border-color1 ' : '' }`}>
                Login
              </li>
            </ul>
          </div>
        )}
        opened={ openModal }>
        { activeTab == 0 && (
          <Fragment>
            <div className='tw-flex tw-flex-wrap md:tw-w-440 tw-mx-auto'>
              <InputText labelText="First name"
                placeholder={ '' }
                defaultValue={ userInfo.first_name }
                event={ inputStr => setUserInfo({ ...userInfo, first_name: inputStr.target.value}) }
                classes={ `tw-w-full md:tw-mt-6 tw-mt-4` } />
              <InputText labelText="Last name"
                placeholder={ '' }
                defaultValue={ userInfo.last_name }
                event={ inputStr => setUserInfo({ ...userInfo, last_name: inputStr.target.value}) }
                classes={ `tw-w-full md:tw-mt-6 tw-mt-4` } />
              <InputText labelText="Email"
                placeholder={ '' }
                defaultValue={ userInfo.email }
                event={ inputStr => setUserInfo({ ...userInfo, email: inputStr.target.value}) }
                classes={ `tw-w-full md:tw-mt-6 tw-mt-4` } />
              <InputText labelText="Zip code"
                id="zipcode"
                placeholder=""
              classes={ `tw-w-full md:tw-mt-6 tw-mt-4` }
                defaultValue={ userInfo.zipcode }
                event={ inputStr => changeZipcode(inputStr.target.value) }/>
              <InputText labelText="Password"
                placeholder=""
                type="password"
                classes={ `tw-w-full md:tw-mt-6 tw-mt-4` }
                event={ inputStr => setUserInfo({ ...userInfo, password: inputStr.target.value}) } />
              <input type="hidden" defaultValue={ userInfo.city } />
              <input type="hidden" defaultValue={ userInfo.state } />
            </div>
            <div className="tw-flex tw-mt-12 tw-justify-center">
              <button
                onClick={ () => registerUserInfo() }
                className="tw-relative tw-py-4 tw-px-16 tw-bg-color1 tw-text-white tw-text-md tw-uppercase tw-rounded-full tw-border-none tw-cursor-pointer">
                { !loading && 'CREATE AN ACCOUNT' }
                { loading && (<LoadingSpinner />) }
              </button>
            </div>
          </Fragment>
        )}
        { activeTab == 1 && (
          <Fragment>
            <div className='tw-flex tw-flex-wrap md:tw-w-440 tw-mx-auto'>
              <InputText labelText="Email"
                placeholder={ '' }
                defaultValue={ userInfo.email }
                event={ inputStr => setUserInfo({ ...userInfo, email: inputStr.target.value}) }
                classes={ `tw-w-full md:tw-mt-6 tw-mt-4` } />
              <InputText labelText="Password"
                placeholder=""
                type="password"
                classes={ `tw-w-full md:tw-mt-6 tw-mt-4` }
                event={ inputStr => setUserInfo({ ...userInfo, password: inputStr.target.value}) } />
            </div>
            <div className="tw-flex tw-mt-12 tw-justify-center">
              <button
                onClick={ () => loginUserInfo() }
                className="tw-relative tw-py-4 tw-px-16 tw-bg-color1 tw-text-white tw-text-md tw-uppercase tw-rounded-full tw-border-none tw-cursor-pointer">
                { !loading && 'LOGIN' }
                { loading && (<LoadingSpinner />) }
              </button>
            </div>
          </Fragment>
        )}
        { (errors.full_message || errors.error) && (<div className='tw-text-color2 tw-m-4'>{ errors.full_message || errors.error }</div>) }
      </Modal>
    </Fragment>
  );
}