import 'fomantic-ui/dist/semantic.css';
import 'fomantic-ui';
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'nodep-date-input-polyfill';
// import 'lazysizes';
import '../css/tailwind.scss'
import LoginModal from '../components/login_modal';
import '../category_filter/style.scss'
import '../common/global.scss';

document.addEventListener("DOMContentLoaded", ()=>{
  let dropdowns = document.querySelectorAll(".mobile-hover-dropdown")
  dropdowns.forEach(menu => {
    menu.addEventListener("click", (e)=>{
      const submenu = e.target.querySelector(".menu")

      submenu && submenu.classList.toggle("hidden")
    })
  })

  const LoginModalContainer = document.getElementById('LoginModal')
  if (LoginModalContainer) {
    const LoginModalRoot = createRoot(LoginModalContainer)
    const LoginModalFilters = JSON.parse(LoginModalContainer.dataset.params)
    LoginModalRoot.render(<LoginModal {...LoginModalFilters} />)
  }

  const whitelist = window.privateUrls || [];

  const targetTypeByURL = {
    'buy_ticket': '/buy_ticket',
    'hire': '/purchases/new',
    'class': '/classes',
    'service': '/services',
    'product': '/products',
    'event': '/events',
    'project': '/projects',
    'nonprofit': '/organizations',
    'sign_up': '/sign_up',
    'offer': '/offer',
    'chat': '/chat'
  }

  if (whitelist.length > 0) {
    document.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', (event) => {
        console.log(event.target.href)
        if (whitelist.find( (url) => event.target.href && event.target.href.includes(url) )) {
          const selectType = Object.keys(targetTypeByURL).find((type) => event.target.href.includes(targetTypeByURL[type]))
          let eventData = new CustomEvent("OpenLoginModal", { detail: {
            open: true,
            targetType: selectType || 'other',
            targetUrl: event.target.href,
            activeTab: event.target.href.includes('sign_in') ? 1 : 0
          } });
          window.dispatchEvent(eventData)
          event.preventDefault()
        }
      })
    })
  }
});