import React from 'react'

const InputText = ({labelText, placeholder, textLength, id, event, disabled, classes = '', showInputLabel = null, defaultValue= null, type='text'}) => {
  return(
    <div className={ `step-input-field tw-flex tw-flex-col ${classes}` }>
      {labelText && (<label className="tw-text-md tw-mb-2" htmlFor={ id }>
        { labelText }
      </label>)}
      <div className="tw-relative">
        <input type={type || "text"}
          id={ id }
          placeholder={ placeholder }
          defaultValue={ defaultValue }
          className={
            `tw-full tw-outline-none tw-p-4 tw-font-xl tw-rounded-lg tw-border tw-border-solid tw-border-icons tw-w-full
            ${disabled ? 'tw-bg-icons tw-bg-deep-blue tw-bg-opacity-10' : 'tw-bg-grey-light'}`
          }
          maxLength={ textLength }
          onBlur={ event }
          disabled={ disabled } />
        {
          (
            showInputLabel &&
            <span className="tw-absolute tw-py-3 tw-px-8 tw-right-0 tw-rounded-r-lg tw-bg-main2 tw-text-white tw-text-lg">
              { showInputLabel }
            </span>
          )
        }
      </div>
    </div>
  )
}

export default InputText